"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThemeContext = exports.ThemeProvider = void 0;
/* eslint-disable no-unused-vars */
const react_1 = __importStar(require("react"));
const contextValue = {
    colors: {
        dark: "#08324F",
        primaryDark: "#3987FF",
        primaryLight: "#57ABE7",
        secondaryDark: "#3987FF",
        secondaryLight: "#9ea9b4",
        lightGray: "#9ea9b4",
        white: "#FFF",
        secondaryOne: "rgba(0,89,174,229)",
    },
};
const ThemeContext = (0, react_1.createContext)(contextValue);
function ThemeProvider({ children }) {
    return (react_1.default.createElement(ThemeContext.Provider, { value: contextValue }, children));
}
exports.ThemeProvider = ThemeProvider;
// Create a custom hook to simplify context usage
function useThemeContext() {
    return (0, react_1.useContext)(ThemeContext);
}
exports.useThemeContext = useThemeContext;
