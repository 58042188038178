"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
function ScrollToTop() {
    const { pathname, state } = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        var _a;
        if (state && state.scrollToElement) {
            (_a = document.getElementById(state.scrollToElement)) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
        else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "auto",
            });
        }
    }, [pathname]);
    return null;
}
exports.default = ScrollToTop;
