"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
exports.default = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const paths = [
        {
            name: "Home",
            path: "/" /* RoutePath.HOME */,
        },
        // {
        //   name: "About",
        //   path: RoutePath.ABOUT_US,
        // },
        // {
        //   name: "Pricing",
        //   path: RoutePath.HOME,
        //   id: "pricing-container",
        // },
        //   {
        //     name: "FAQ",
        //     path: "",
        //     active: false,
        //     id: "faq-container",
        //   },
    ];
    return paths.map((path) => (Object.assign(Object.assign({}, path), { active: location.pathname === path.path && !path.id })));
};
